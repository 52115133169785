import React, { useState, useEffect } from "react"
import Layout, { MyLocationEnum } from "../components/Layout"
import SEO, { MetaOG } from "../components/Seo"
import { graphql } from "gatsby"
import VideoRooms from '../components/StoryRooms/VideoRooms'
import { TransitionStatus } from "react-transition-group/Transition"
import MobileVideoRoom from "../components/StoryRooms/MobileVideoRoom";
import greetingsImageSRC from '../images/velkomin-i-biosal-kall.png'

interface StoryRoomProps {
  data: any,
  location: Location,
  transitionStatus: TransitionStatus,
}

export default ({ data, location, transitionStatus }: StoryRoomProps) => {
  //const [showInfo, setShowInfo] = useState(false)
  const [enableTilt, setEnableTilt] = useState(true)

  const seo_og: MetaOG = {
    title: "Bíósalur",
    description: "",
  }

  const [showMobile, setShowMobile] = useState(false)
  useEffect(() => {
    var resp = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    setShowMobile(resp)
  },)
  if(!showMobile) {
    return (
      <Layout 
        myLocation={MyLocationEnum.Biosalur}
        location={location}
        transitionStatus={transitionStatus}
      >
        {({handleInfoOpen,infoOpen})=>(
          <>
          <SEO
            title="Bíósalur"
            description=""
            og={seo_og}
          />
          <VideoRooms 
            greetingsImageSRC={greetingsImageSRC}
            active={true}
            data={data}
            enableTilt={enableTilt} 
            showInfo={infoOpen} 
            transitionStatus={transitionStatus}
            onInfo={handleInfoOpen}
            floorColor="#D42A3F"
            topColor="#E6E6E6"
            rightColor="#C8C8C8"
            leftColor="#C8C8C8"
            backColor="#969696"

          />
        </>
        )}
      </Layout>
    )
  } else {
    return (
      <Layout 
        myLocation={MyLocationEnum.Biosalur}
        location={location}
        transitionStatus={transitionStatus}
      >
          <SEO
            title="Nærmynd"
            description="Ýmsar sögur úr bransanum"
            og={seo_og}
          />
          <MobileVideoRoom 
            data={data}
          />
      </Layout>
    )
  }

}

export const query = graphql`
  query {
    allStory (sort: {fields: order, order: ASC}){
      edges {
        node {
          speakerName
          companyName
          videolink
          order
          birting
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 500, quality: 60) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    } 
    afsakidhle: file(relativePath: { eq: "afsakid.png" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
  } 
`


